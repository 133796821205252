<template>
  <page-container title="关于本系统" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;background-color: white">
<!--      <h2 style="width:100%; margin-top: 10px;font-size: 20px">关于本系统</h2>-->
      <div class="box-container-inner">
        <h2 style="text-align: center;font-weight: bold">全域监管培训平台是什么？</h2>
<!--        <h3 style="font-weight: bold">一、描述</h3>-->
        <span style="margin-left: 20px">全域监管培训平台是一个集专业性、全域性、实时性、便捷性、精准性、闭环性及预防性为一体的综合物业品质监管平台，其主要作用是助力物业企业向业主提供高效、低耗、灵活、准时、满意的服务。</span>
<!--        <h3 style="font-weight: bold">二、解释</h3>-->
        <h4 style="font-weight: bold;margin-left: 20px">1.专业性</h4>
        <span style="margin-left: 30px">以物业管理的业务逻辑和管理思路开发而不是由模块拼凑而成，是贴合物业管理整体品质管控的组合工具。</span>
        <h4 style="font-weight: bold;margin-left: 20px">2.全域性</h4>
        <span style="margin-left: 30px">涵括了物业管理品质监管的各项业务、各相关岗位（包括基层和管理岗位）以及总部、区域的一体化监管。它们之间是联动关系而不是上下层级管理关系，各环节同心同向保证品质的稳定和提升。</span>
        <h4 style="font-weight: bold;margin-left: 20px">3.实时性</h4>
        <span style="margin-left: 30px">实时反映（包括报警和状态信号、图像、语音、记录等）物业管理的异常情况，并能随时和相关岗位进行点对点沟通和对状态实行监管。</span>
        <h4 style="font-weight: bold;margin-left: 20px">4.便捷性</h4>
        <span style="margin-left: 30px">具有强大的纵向和横向的穿透能力，通过丰富的筛选条件和弹窗在一个页面里灵活快速地得到关联性的信息。且通过批量导出功能实现报表和信息输出。一键报告等分析和报告功能大大减少了品质管理人员的工作量且准确高效。</span>
        <h4 style="font-weight: bold;margin-left: 20px">5.精确性</h4>
        <span style="margin-left: 30px">精准定位发生异常情况所涉及的事件、人、环节和时点，通过报警、调度、查阅和分析功能发现问题所在，及时、定向、有效地解决根本问题。</span>
        <h4 style="font-weight: bold;margin-left: 20px">6.闭环性</h4>
        <span style="margin-left: 30px">每项异常情况的处理都能在平台实现全过程跟踪，直到处理完成并由发起岗位关闭。整个处理过程可追溯和分析。</span>
        <h4 style="font-weight: bold;margin-left: 20px">7.预防性</h4>
        <span style="margin-left: 30px">通过数据分析和工作查阅功能得到有效的信息，及时正确地制定或调整相应的服务方案和管理手段，防止问题的发生或再次发生。</span>
        <a-divider></a-divider>
        <a-form-model ref="userForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="queryForms" :label-col="{span:7}" :wrapper-col="{span:12}" style="">
            <a-form-model-item label="平台版本" prop="platform_version">
              <span>{{platform_version}}</span>
            </a-form-model-item>
<!--            <a-form-model-item label="运行环境" prop="jdkVersion">-->
<!--              <span>{{queryForms.jdkVersion}}</span>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="操作系统" prop="osArch">-->
<!--              <span>{{queryForms.osArch}}</span>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="内存总量" prop="total">-->
<!--              <span>{{queryForms.total}}</span>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="空闲内存量" prop="free">-->
<!--              <span>{{queryForms.free}}</span>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="磁盘空间总量" prop="disktotal">-->
<!--              <span>{{queryForms.disktotal}}</span>-->
<!--            </a-form-model-item>-->
<!--            <a-form-model-item label="剩余磁盘空间" prop="diskfree">-->
<!--              <span>{{queryForms.diskfree}}</span>-->
<!--            </a-form-model-item>-->
            <a-form-model-item label="平台开发单位" prop="unit">
              <span>{{unit}}</span>
            </a-form-model-item>
<!--            <a-form-model-item label="开发平台网站" prop="website">-->
<!--              <a-input v-model.trim="website" :read-only="true"/>-->
<!--            </a-form-model-item>-->
            </a-form-model>
      </div>
    </div>
  </page-container>
</template>
<script>
import {getSystemInfo, modifyPersonInfo} from 'A/xtpz.js'
import {getCache, getItemFromArrayByKey} from 'U'
import store from '@/store'
import { mapState } from 'vuex'
export default {
  name: 'aboutSystem',
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '关于本系统',
          path: ''
        },
      ],
      platform_version:'2.1.5',
      unit:"南京优湖科技有限公司",
      website:"www.u-lake.com",
      queryForms:{
        disktotal:'',
        diskfree:'',
        total:'',
        free:'',
        jdkVersion:'',
        osArch:'',
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      getSystemInfo().then(res=>{
        if(res&&res.returncode=='0'){
          this.queryForms.disktotal=parseFloat(res.disktotal).toFixed(2)+"GB"
          this.queryForms.diskfree=parseFloat(res.diskfree).toFixed(2)+"GB"
          this.queryForms.total=res.total
          this.queryForms.free=res.free
          this.queryForms.jdkVersion=res.jdkVersion
          this.queryForms.osArch=res.osArch
        }
      })
    },
  }
}
</script>
